.image-box-card2 {
  opacity: 0;
  animation-duration: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

.image-box-card2 .ibc-head {
  position: relative;
  max-width: 400px;
}

.image-box-card2 .ibc-head::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}

.image-box-card2 .ibc-head:hover::after {
  opacity: 1;
  visibility: visible;
}

.image-box-card2 .ibc-head a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 5px 15px;
  color: #333;
  text-decoration: none;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}

.image-box-card2 .ibc-head:hover a {
  opacity: 1;
  visibility: visible;
}

.image-box-card2 .ibc-content-cat span {
  display: inline-block;
}

.image-box-card2 .ibc-content-cat span::after {
  content: "/";
  display: inline-block;
  margin: 0 5px;
  font-size: 14px;
}

.image-box-card2 .ibc-content-cat span:nth-last-of-type(1)::after {
  display: none;
}
