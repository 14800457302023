.gallery-4-column {
  font-size: 26px;
}

.gallery-4-column .g4c-inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
}

/* .gallery-4-column .g4c-inner::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.6s;
}
 */

/* .gallery-4-column .g4c-inner:hover {
  top: -10px;
} */

.gallery-4-column .g4c-inner div {
  position: relative;
  color: #fff;
  font-size: 21px;
  margin-bottom: 40px;
  z-index: 2;
  text-align: center;
}

@media (min-width: 768px) {
  .gallery-4-column .g4c-inner {
    height: 350px;
  }
}

@media (min-width: 996px) {
  .gallery-4-column .g4c-inner {
    height: 400px;
  }
  .gallery-4-column .g4c-inner:hover::before {
    background-color: #3452ff;
  }
}
