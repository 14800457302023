.products-introduct .pi-line {
  height: 3px;
  width: 70px;
  background-color: #3452ff;
  background: -moz-linear-gradient(left, #2f9bea 0%, #75ceae 100%) !important;
  background: -webkit-linear-gradient(
    left,
    #2f9bea 0%,
    #75ceae 100%
  ) !important;
  background: linear-gradient(to right, #2f9bea 0%, #75ceae 100%) !important;
  margin: auto;
  margin-bottom: 30px;
}
