.navigation-prev,
.navigation-next {
  position: fixed;
  top: 50%;
  z-index: 10000;
  width: 100px;
  display: none;
  transform: translateY(-50%);
  transition: all 0.4s ease-in-out;
}

.navigation-prev {
  left: -100px;
}

.navigation-prev:hover {
  left: 0;
}

.navigation-next {
  right: -100px;
}

.navigation-next:hover {
  right: 0;
}

.navigation-prev::before {
  content: attr(data-name);
  text-transform: uppercase;
  opacity: 1;
  font-size: 14px;
  line-height: 50px;
  width: 100px;
  text-align: center;
  right: -75px;
  display: block;
  background: #f4f4f4;
  height: 50px;
  top: 25px;
  position: absolute;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
}

.navigation-next::before {
  content: attr(data-name);
  text-transform: uppercase;
  opacity: 1;
  font-size: 14px;
  line-height: 50px;
  width: 100px;
  text-align: center;
  left: -75px;
  display: block;
  background: #f4f4f4;
  height: 50px;
  top: 25px;
  position: absolute;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
}

@media (min-width: 992px) {
  .navigation-prev,
  .navigation-next {
    display: block;
  }
}
