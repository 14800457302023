.left-image-right-content .lirc-ttl {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: normal;
  line-height: 1.25;
}

.left-image-right-content p {
  margin: 0 0 10px;
}

.left-image-right-content .grey-block {
  color: #acacac;
  display: block;
}
