.scroll-load-data button.active {
  border-color: #3452ff !important;
  background-color: #3452ff !important;
  color: #ffffff;
}

.scroll-load-data button {
  border: 2px solid #eeeeee;
  transition: 0.6s all;
  outline: unset;
}

.scroll-load-data .btn-group {
  display: flex;
  flex-wrap: wrap;
}

.scroll-load-data .btn {
  margin-bottom: 5px;
  border-radius: 0;
}

.scroll-load-data button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

.scroll-load-data button:nth-last-of-type(1),
.scroll-load-data button:nth-of-type(1) {
  border-right: 2px solid #eeeeee;
}

.scroll-load-data button:hover {
  background-color: #3452ff !important;
}
