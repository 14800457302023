.contact-us-form {
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  background-color: #f8f8f8;
}

.contact-us-form input {
  height: 46px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 14px;
  color: #2a2f35;
  font-size: 14px;
  vertical-align: middle;
  box-shadow: none;
  border-radius: 0;
  font-weight: 400;
}

.contact-us-form textarea {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 14px;
  color: #2a2f35;
  font-size: 14px;
  vertical-align: middle;
  box-shadow: none;
  border-radius: 0;
  font-weight: 400;
}

.contact-us-form .contact-us-form-btn {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  padding: 0;
  width: 100%;
  padding: 10px 20px;
  background: #2c99ed;
  display: block;
  -webkit-appearance: none;
  border: 0;
  font-style: normal !important;
  cursor: pointer;
  font-style: normal;
  border-radius: 0;
  text-transform: uppercase;
}

.contact-us-form .contact-us-form-btn:hover {
  color: #fff;
  opacity: 0.9;
}
