.about-us-banner {
  position: relative;
  overflow: hidden;
  background-color: #000000;
}

.about-us-banner img {
  position: relative;
  width: 100%;
  min-width: 73rem;
  left: 50%;
  transform: translateX(-50%);
}

.about-us-banner .aub-content {
  position: absolute;
  color: #ffffff;
  top: 340px;
  width: 80%;
  right: 40px;
  text-align: right;
}

.about-us-banner .aub-title {
  font-size: 2.5em;
  font-weight: bold;
}

.about-us-banner .aub-name {
  font-size: 4.5em;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1;
  margin-bottom: 1rem;
  font-weight: 500;
}

.about-us-banner .aub-text {
  font-size: 1.5em;
  letter-spacing: 5px;
  line-height: 1.8;
}

@media (min-width: 600px) {
  .about-us-banner .aub-content {
    top: 50%;
    transform: translateY(-50%);
    max-width: 400px;
    right: 50px;
  }
}

@media (min-width: 992px) {
  .about-us-banner .aub-content {
    position: absolute;
    color: #ffffff;
    top: 50%;
    width: 30vw;
    min-width: 500px;
    transform: translateY(-50%);
    text-align: left;
    left: 50%;
  }
}
