.image-box-card .ibc-head {
  position: relative;
}

.image-box-card .ibc-head::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}

.image-box-card .ibc-head:hover::after {
  opacity: 1;
  visibility: visible;
}

.image-box-card .ibc-head a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 5px 15px;
  color: #333;
  text-decoration: none;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}

.image-box-card .ibc-head:hover a {
  opacity: 1;
  visibility: visible;
}
