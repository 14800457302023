.isotope-instance .btn-group {
  display: flex;
  flex-wrap: wrap;
}

.isotope-instance .btn {
  white-space: nowrap !important;
  margin-bottom: 5px;
  border-radius: 0;
}
