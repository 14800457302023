body,
body.en {
  font-family: "Poppins", sans-serif !important;
}

body.tc {
  font-family: "Noto Sans TC", sans-serif !important;
}

body.sc {
  font-family: "Noto Sans SC", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  color: #666666;
  font-size: "100%";
}

body.overflow-y-hidden {
  overflow-y: hidden;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.error {
  color: red;
}

.cursor-pointer {
  cursor: pointer;
}

.color-grey {
  color: #757575;
}

a,
.color-blue {
  color: #0e91ee;
}

.gsc-adBlock {
  display: none !important;
}

.logo {
  width: 180px;
}

@keyframes fadeIn {
  form {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* @media (min-width: 768px) {
  .logo {
    width: 140px;
  }
}
 */
